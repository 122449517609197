import React, { lazy, Suspense } from 'react';


import { Route, Routes } from 'react-router-dom';



import AppLayout from './appLayout/AppLayout';


import SpinnerPage from './components/commun/SpinnerPage';

import RequireAuth from './validator/RequireAuth';
import RequireRole from './validator/RequireRole';
import { useSingupConfig } from './reduxhooks/ReduxHooks';

const MainView = lazy(() => import('./views/landingPage/MainView'));

const CPanelView = lazy(() => import('./views/cPanel/CPanelView'));
const ThemeConfigView = lazy(() => import('./views/cPanel/ThemeConfigView'));
const PlateformConfigView = lazy(() => import('./views/cPanel/PlateformConfigView'));

const UsersManagerView = lazy(() => import('./views/usersManager/UsersManagerView'));
const SingUpView = lazy(() => import('./views/auth/SingUpView'));
const SingInView = lazy(() => import('./views/auth/SingInView'));
const Page403View = lazy(() => import('./views/resultPages/Page403View'));
const PageDateSingupOverView = lazy(() => import('./views/resultPages/PageDateSingupOverView'));

const PlateformLogoView = lazy(() => import('./views/cPanel/Plateforme/PlateformLogoView'));
const PlateformPictureView = lazy(() => import('./views/cPanel/Plateforme/PlateformPictureView'));
const PresidentWordView = lazy(() => import('./views/cPanel/Plateforme/PresidentWordView'));
const ContactDetailsView = lazy(() => import('./views/cPanel/Plateforme/ContactDetailsView'));
const SocialMediasView = lazy(() => import('./views/cPanel/Plateforme/SocialMediasView'));
const CatSponsorsView = lazy(() => import('./views/cPanel/Plateforme/CatSponsorsView'));
const SponsorsView = lazy(() => import('./views/cPanel/Plateforme/SponsorsView'));
const TeamCatView = lazy(() => import('./views/cPanel/Plateforme/TeamCatView'));
const TeamView = lazy(() => import('./views/cPanel/Plateforme/TeamView'));
const ThemesView = lazy(() => import('./views/cPanel/Plateforme/ThemesView'));
const InfosBarView = lazy(() => import('./views/cPanel/Plateforme/InfosBarView'));
const SingupConfigView = lazy(() => import('./views/cPanel/Plateforme/SingupConfigView'));
const PackView = lazy(() => import('./views/cPanel/Plateforme/PackView'));
const PackCatView = lazy(() => import('./views/cPanel/Plateforme/PackCatView'));
const PayementListView = lazy(() => import('./views/cPanel/Plateforme/PayementListView'));
const PayementListCatView = lazy(() => import('./views/cPanel/Plateforme/PayementListCatView'));
const PartnerView = lazy(() => import('./views/cPanel/Plateforme/PartnerView'));
const AbstractCatView = lazy(() => import('./views/cPanel/Plateforme/AbstractCatView'));
const AbstractConfigView = lazy(() => import('./views/cPanel/Plateforme/AbstractConfigView'));
const PrivacyPolicy = lazy(() => import('./views/pp/PrivacyPolicy'));

const PgmDayConfigView = lazy(() => import('./views/cPanel/Plateforme/PgmDayConfigView'));
const PgmRoomConfigView = lazy(() => import('./views/cPanel/Plateforme/PgmRoomConfigView'));
const PgmItemsConfigView = lazy(() => import('./views/cPanel/Plateforme/PgmItemsConfigView'));


const ChairmanMessageView = lazy(() => import('./views/ChairmansMessage/ChairmanMessageView'));
const OurTeamView = lazy(() => import('./views/OurTeam/OurTeamView'));
const ContactView = lazy(() => import('./views/Contact/ContactView'));
const ShowTheEventView = lazy(() => import('./views/ShowTheEvent/ShowTheEventView'));
const SponsorsViewRender = lazy(() => import('./views/sponsors/SponsorsView'));
const SponsorsCatViewRender = lazy(() => import('./views/sponsors/SponsorsCatView'));
const OurPartnerView = lazy(() => import('./views/ourPartner/OurPartnerView'));
const AbstractsCatView = lazy(() => import('./views/abstract/AbstractCatView'));
const AbstractView = lazy(() => import('./views/abstract/AbstractView'));
const AbstractPdfView = lazy(() => import('./views/abstract/AbstractPdfView'));
const AbstractPptView = lazy(() => import('./views/abstract/AbstractPtpView'));

const PgmDayView = lazy(() => import('./views/Program/PgmDayView'));
const PgmRoomView = lazy(() => import('./views/Program/PgmRoomView'));
const PgmItemView = lazy(() => import('./views/Program/PgmItemView'));

var dates = {
  convert: function (d) {

    return (
      d.constructor === Date ? d :
        d.constructor === Array ? new Date(d[0], d[1], d[2]) :
          d.constructor === Number ? new Date(d) :
            d.constructor === String ? new Date(d) :
              typeof d === "object" ? new Date(d.year, d.month, d.date) :
                NaN
    );
  },
  compare: function (a, b) {
    // Compare two dates (could be of any type supported by the convert
    // function above) and returns:
    //  -1 : if a < b
    //   0 : if a = b
    //   1 : if a > b
    // NaN : if a or b is an illegal date
    // NOTE: The code inside isFinite does an assignment (=).
    return (
      isFinite(a = this.convert(a).valueOf()) &&
        isFinite(b = this.convert(b).valueOf()) ?
        (a > b) - (a < b) :
        NaN
    );
  },
  inRange: function (d, start, end) {
    // Checks if date in d is between dates in start and end.
    // Returns a boolean or NaN:
    //    true  : if d is between start and end (inclusive)
    //    false : if d is before start or after end
    //    NaN   : if one or more of the dates is illegal.
    // NOTE: The code inside isFinite does an assignment (=).
    return (
      isFinite(d = this.convert(d).valueOf()) &&
        isFinite(start = this.convert(start).valueOf()) &&
        isFinite(end = this.convert(end).valueOf()) ?
        start <= d && d <= end :
        NaN
    );
  }
}


export default function App() {


  const singupConfig = useSingupConfig();

  const verifyDate = () => {

    let dateNow = new Date();

    const resultDate = dates.compare(singupConfig.dateLimit, dateNow);

    if (resultDate === 1 || resultDate === 0) {
      return true
    } else { return false }

  }

  return (




    <Routes>
      <Route path="/" element={<AppLayout />}>
        <Route path="/" element={<Suspense fallback={<SpinnerPage />}><MainView /></Suspense>} />

        <Route path="/Unauthorized" element={<Suspense fallback={<SpinnerPage />}><Page403View /></Suspense>} />

        <Route path="/Singup" element=
          {
            <Suspense fallback={<SpinnerPage />}>

              {singupConfig.dateLimit ? verifyDate() ? <SingUpView /> : <PageDateSingupOverView /> : <SingUpView />}

            </Suspense>
          } />

        <Route path="/Singin" element={<Suspense fallback={<SpinnerPage />}><SingInView /></Suspense>} />
        <Route path="/Chairmans-message" element={<Suspense fallback={<SpinnerPage />}><ChairmanMessageView /></Suspense>} />
        <Route path="/Our-Team" element={<Suspense fallback={<SpinnerPage />}><OurTeamView /></Suspense>} />
        <Route path="/Contact" element={<Suspense fallback={<SpinnerPage />}><ContactView /></Suspense>} />
        <Route path="/ShowTheEvent" element={<Suspense fallback={<SpinnerPage />}><ShowTheEventView /></Suspense>} />
        <Route path="/Our-Sponsors" element={<Suspense fallback={<SpinnerPage />}><SponsorsViewRender /></Suspense>} />
        <Route path="/Our-Sponsors-Cat" element={<Suspense fallback={<SpinnerPage />}><SponsorsCatViewRender /></Suspense>} />
        <Route path="/Program" element={<Suspense fallback={<SpinnerPage />}><PgmDayView /></Suspense>} />
        <Route path="/Program-room" element={<Suspense fallback={<SpinnerPage />}><PgmRoomView /></Suspense>} />
        <Route path="/Program-items" element={<Suspense fallback={<SpinnerPage />}><PgmItemView /></Suspense>} />
        <Route path="/Our-Collaborator" element={<Suspense fallback={<SpinnerPage />}><OurPartnerView /></Suspense>} />
        <Route path="/Abstracts-Cat" element={<Suspense fallback={<SpinnerPage />}><AbstractsCatView /></Suspense>} />
        <Route path="/Abstract-View" element={<Suspense fallback={<SpinnerPage />}><AbstractView /></Suspense>} />
        <Route path="/eposter-Viewr-pdf" element={<Suspense fallback={<SpinnerPage />}><AbstractPdfView /></Suspense>} />
        <Route path="/eposter-Viewr-ppt" element={<Suspense fallback={<SpinnerPage />}><AbstractPptView /></Suspense>} />
        <Route path="/PrivacyPolicy" element={<Suspense fallback={<SpinnerPage />}><PrivacyPolicy /></Suspense>} />



        {/* <Route path="/VerifyAccount" element={<Suspense fallback={<SpinnerPage />}><VerifyAccountView /></Suspense>} /> */}
        {/* <Route path="/ForgetPassword" element={<Suspense fallback={<SpinnerPage />}><ForgetPasswordView /></Suspense>} />
        <Route path="/FPESuccess" element={<Suspense fallback={<SpinnerPage />}><FPESuccessView /></Suspense>} />
        <Route path="/ResetPassword" element={<Suspense fallback={<SpinnerPage />}><ResetPasswordView /></Suspense>} />

        <Route path="/testYoutube" element={<Suspense fallback={<SpinnerPage />}><TestYoutube /></Suspense>} /> */}
        {/* <Route path="/Poco" element={<Suspense fallback={<SpinnerPage />}><Poco /></Suspense>} /> */}

        <Route element={<RequireAuth />}>

          {/* <Route path="/PayementPv" element={<Suspense fallback={<SpinnerPage />}><PayementView /></Suspense>} />
          <Route path="/Program" element={<Suspense fallback={<SpinnerPage />}><ProgramView /></Suspense>} />
          <Route path="/Abstract" element={<Suspense fallback={<SpinnerPage />}><AbstractUserView /></Suspense>} />
 */}
          <Route element={<RequireRole allowedRoles={['SuperAdmin']} />}>

            <Route path="/ThemesManager" element={<Suspense fallback={<SpinnerPage />}><ThemeConfigView /></Suspense>} />

            <Route path="/PlateformManager" element={<Suspense fallback={<SpinnerPage />}><PlateformConfigView /></Suspense>} />



            <Route path="/PlateformLogo" element={<Suspense fallback={<SpinnerPage />}><PlateformLogoView /></Suspense>} />
            <Route path="/PlateformPicture" element={<Suspense fallback={<SpinnerPage />}><PlateformPictureView /></Suspense>} />
            <Route path="/PresidentWord" element={<Suspense fallback={<SpinnerPage />}><PresidentWordView /></Suspense>} />
            <Route path="/ContactConfig" element={<Suspense fallback={<SpinnerPage />}><ContactDetailsView /></Suspense>} />
            <Route path="/SocialMediaConfig" element={<Suspense fallback={<SpinnerPage />}><SocialMediasView /></Suspense>} />
            <Route path="/SponsorsCat" element={<Suspense fallback={<SpinnerPage />}><CatSponsorsView /></Suspense>} />
            <Route path="/SponsorsConfig" element={<Suspense fallback={<SpinnerPage />}><SponsorsView /></Suspense>} />
            <Route path="/TeamCat" element={<Suspense fallback={<SpinnerPage />}><TeamCatView /></Suspense>} />
            <Route path="/TeamConfig" element={<Suspense fallback={<SpinnerPage />}><TeamView /></Suspense>} />
            <Route path="/ThemesConfig" element={<Suspense fallback={<SpinnerPage />}><ThemesView /></Suspense>} />
            <Route path="/InfosBarConfig" element={<Suspense fallback={<SpinnerPage />}><InfosBarView /></Suspense>} />
            <Route path="/SingupConfig" element={<Suspense fallback={<SpinnerPage />}><SingupConfigView /></Suspense>} />
            <Route path="/PackConfig" element={<Suspense fallback={<SpinnerPage />}><PackView /></Suspense>} />
            <Route path="/PackCatConfig" element={<Suspense fallback={<SpinnerPage />}><PackCatView /></Suspense>} />
            <Route path="/PayementConfig" element={<Suspense fallback={<SpinnerPage />}><PayementListView /></Suspense>} />
            <Route path="/PayementCatConfig" element={<Suspense fallback={<SpinnerPage />}><PayementListCatView /></Suspense>} />
            <Route path="/Our-Partner-Manager" element={<Suspense fallback={<SpinnerPage />}><PartnerView /></Suspense>} />
            <Route path="/Abstract-Cat" element={<Suspense fallback={<SpinnerPage />}><AbstractCatView /></Suspense>} />
            <Route path="/Abstract-manager" element={<Suspense fallback={<SpinnerPage />}><AbstractConfigView /></Suspense>} />

            <Route path="/Pgm-Days" element={<Suspense fallback={<SpinnerPage />}><PgmDayConfigView /></Suspense>} />
            <Route path="/Pgm-Rooms" element={<Suspense fallback={<SpinnerPage />}><PgmRoomConfigView /></Suspense>} />
            <Route path="/Pgm-Items" element={<Suspense fallback={<SpinnerPage />}><PgmItemsConfigView /></Suspense>} />


          </Route>

          <Route element={<RequireRole allowedRoles={['SuperAdmin', 'Admin']} />}>
            <Route path="/CPanel" element={<Suspense fallback={<SpinnerPage />}><CPanelView /></Suspense>} />
            <Route path="/UsersManager" element={<Suspense fallback={<SpinnerPage />}><UsersManagerView /></Suspense>} />
          </Route>
        </Route>
      </Route>
    </Routes>


  )
}
